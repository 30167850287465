export default [
    {
        title: "费用名称",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "费用类型",
        dataIndex: "type",
        key: "type",
        scopedSlots: { customRender: 'type' },
    },
    {
        title: "单价",
        dataIndex: "base_price",
        key: "base_price",
    },
    {
        title: "单位",
        dataIndex: "unit",
        key: "unit",
        scopedSlots: { customRender: 'unit' },

    },
    {
        title: "金额",
        dataIndex: "bill_amount",
        key: "bill_amount",
    },
    {
        title: "费用公式",
        dataIndex: "formula",
        key: "formula",
    },
    {
        title: "核销状态",
        dataIndex: "is_write_off",
        key: "is_write_off",
        scopedSlots: { customRender: 'is_write_off' },

    },

]