/*
 * @Author: your name
 * @Date: 2021-12-13 14:13:05
 * @LastEditTime: 2021-12-13 14:20:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Billing/WaybillFee/curd/columns.js
 */
/*
 * @Author: your name
 * @Date: 2021-12-08 18:10:06
 * @LastEditTime: 2021-12-08 18:16:29
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Address/defAddress/curd/columns.js
 */
import moment from "moment"
export default [
    {
        title: 'FBA运单',
        // dataIndex: 'fbaNo',
        key: 'fbaNo',
        scopedSlots: { customRender: 'fbaNo' },
    }, {
        title: '目的地',
        dataIndex: 'warehouse_code',
        key: 'warehouse_code',
    },
    {
        title: '国家或地区',
        dataIndex: 'destination',
        key: 'destination',
    },
    {
        title: '件数',
        dataIndex: 'cargoes_num',
        key: 'cargoes_num',
    },
    {
        title: "运单号",
        dataIndex: "waybill_no",
        key: "waybill_no",
    },
    {
        title: '转单号',
        dataIndex: 'transship_no',
        key: 'transship_no',
    },
    {
        title: "金额",
        dataIndex: "amount",
        key: "amount",
    },
    {
        title: "包含费用单",
        dataIndex: "cost_count",
        key: "cost_count",
    },
    {
        title: "结算重",
        dataIndex: "bill_weight",
        key: "bill_weight",
    }, {
        title: "发货渠道",
        dataIndex: "channel_name",
        key: "channel_name",
    },
    {
        title: '入仓时间',
        dataIndex: 'irikura_time',
        key: 'irikura_time',
        customRender: (text) => {
            let str = ""
            if (text) {
                str = moment(text * 1000).format("YYYY-MM-DD HH:mm:ss")
            } else if (text === 2) {
                str = "-"
            }
            return str;
        }
    },
    {
        title: "客户备注",
        dataIndex: "remark",
        key: "remark",
    },
    {
        title: '操作',
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
    },
]
