<!--
 * @Author: your name
 * @Date: 2021-12-13 13:59:05
 * @LastEditTime: 2021-12-13 14:30:52
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Billing/WaybillFee/index.vue
-->
<template>
    <div class="page-container">
        <a-form-model layout="inline" :model="form">
            <a-form-model-item label="运单号">
                <a-input v-model="form.waybillNo" placeholder="运单号" />
            </a-form-model-item>
            <a-form-model-item label="下单日期">
                <a-range-picker v-model="form.date" style="width: 260px">
                </a-range-picker>
            </a-form-model-item>
            <a-form-model-item>
                <a-button-group>
                    <a-button type="primary" @click="search">
                        查询
                    </a-button>
                    <a-button @click="reset">
                        重置
                    </a-button>
                </a-button-group>
            </a-form-model-item>
        </a-form-model>

        <div class="fd-line"></div>
        <div style="display:flex;justify-content:space-between;align-items: center;">
            <div class="btn">
                <a-button type="primary" @click="exportWaybill">导出运单</a-button>
            </div>
            <div style="font-size:18px; font-weight:600;margin:30px 0px">
                <span style="margin-right:20px">已出账金额：<span>{{ bill_amount }}元</span></span>
                <span>未出账金额：<span>{{ un_bill_amount }}元</span></span>
            </div>

        </div>
        <a-table size="small" bordered :columns="columns" :data-source="tableData" :scroll="{ x: 'max-content' }"
            :rowKey="(record, index) => index" :pagination="pagination" :customRow="customRow"
            :rowClassName="rowClassName" @change="handlerPage">
            <template slot="fbaNo" slot-scope="record">
                <div style="display:flex;justify-content:space-between;align-items: center;">
                    <span>{{ record.fba_no }}</span>
                    <a-popover title="FBA单号" trigger="click">
                        <template slot="content">
                            <div v-for="(item, index) in fbaList" :key="index">{{ item }}</div>
                        </template>
                        <a-button type="link" @click="checkFba(record)">查看</a-button>
                    </a-popover>
                </div>
            </template>
            <template slot="action" slot-scope="record">
                <a class="btn-a" type="link" @click="check(record)">查看费用单</a>
            </template>
        </a-table>
        <a-drawer title="查看费用单" :width="720" :visible="drawer.show" :body-style="{ paddingBottom: '80px' }"
            :footer-style="{ textAlign: 'right' }" @close="onClose" :maskClosable="false">
            <div class="box">
                <div class="title">
                    <h1>运单号：{{ billNUmber }}</h1>
                    <h1>合计{{ amount }}元</h1>
                </div>
                <div class="table" v-for="(item, index) in infoData" :key="index">
                    <div class="head">
                        <span style="font-weight: bolder">
                            <div style="display:inline-block;width:100px">
                                费用{{ index + 1 + ":" + item.amount + "元" }}
                            </div>

                            <div style="display:inline-block;margin-left:15px">
                                <span class="accounts" v-if="item.is_bill == 1">已出账</span>
                                <span class="unaccounts" v-else>未出账</span>
                            </div>

                            <div style="display:inline-block;margin-left:30px">
                                <span class="verification" v-if="stateColl[index] == 1 && item.is_bill == 1">已核销</span>
                                <span class="un_verification"
                                    v-else-if="stateColl[index] == 2 && item.is_bill == 1">未核销</span>
                                <span class="part_verification" v-else>部分核销</span>
                            </div>
                        </span>
                        <span>{{
                                moment(item.created_at * 1000).format("YYYY-MM-DD HH:mm:ss")
                        }}</span>
                    </div>
                    <a-table size="small" :columns="detColumns" :scroll="{ x: 'max-content' }" :data-source="item.costs"
                        :pagination="false">
                        <template slot="type" slot-scope="record">
                            <span>{{ record == "1" ? "基础运费" : "附加费" }}</span>
                        </template>
                        <span slot="unit" slot-scope="record">
                            {{ record == "1" ? "KG" : record == "2"?"单价":"票" }}
                        </span>
                        <span slot="is_write_off" slot-scope="record">
                            {{ record == "1" ? "已核销" : "未核销" }}
                        </span>
                    </a-table>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import columns from "./curd/columns";
import detColumns from "./curd/detColumns";
import moment from "moment";
import {
    waybillFeeList,
    expenseCosts
} from "@/api/Billing";
import {
    exportData
} from "@/api/Export"
import {
    getFba
} from "@/api/Waybill";
export default {
    name: "WaybillFee",
    data() {
        return {
            selectIndex: null,
            fbaList: [],
            tableData: [],
            columns: columns,
            moment,
            detColumns,
            drawer: {
                show: false,
            },
            form: {
                page: 1,
                limit: 20,
                date: [],
                waybillNo: '',
                createdDateStart: "",
                createdDateEnd: ''
            },
            stateColl: [],
            billNUmber: "",
            typeDate: [],
            infoData: [],
            bill_amount: "",
            un_bill_amount: '',
            amount: 0,
            pagination: {
                current: 1,
                total: 0,
                pageSize: 20,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },

        };
    },
    created() {
        this.getData();
    },
    methods: {
        // 点击的样式
        rowClassName(record, index) {
            return (index === this.selectIndex) ? 'row-active' : ''
        },
        // 点击的效果
        customRow(record, index) {
            return {
                on: {
                    click: () => {
                        this.selectIndex = index;
                        console.log(this.selectIndex)
                    }
                }
            }
        },
        exportWaybill() {
            exportData({
                fileName: "导出运单",
                url: '/finance/expense/lists/export',
                // params: {
                //     waybillIds: this.selectedRowKeys
                // },
                fileType: 'xlsx'
            })
        },
        checkFba(val) {
            getFba({
                waybillId: val.waybill_id
            }).then(res => {
                this.fbaList = res.data
            })
        },
        // 查询
        search() {
            this.form.page = 1;
            this.pagination.current = 1;
            if (this.form.createdDateStart) {
                this.form.createdDateStart = moment(this.form.date[0]._d).format('YYYY-MM-DD')
                this.form.createdDateEnd = moment(this.form.date[1]._d).format('YYYY-MM-DD')
            }
            this.getData();
        },
        //重置
        reset() {
            this.form.date = [];
            this.form.waybillNo = '',
                this.form.createdDateStart = "",
                this.form.createdDateEnd = ''
            this.getData();
        },

        handlerPage(p) {
            this.form.page = p.current;
            this.form.limit = p.pageSize;
            this.pagination.current = p.current;
            this.pagination.pageSize = p.pageSize;
            this.getData();
        },
        getData() {
            waybillFeeList(this.form).then((res) => {
                this.bill_amount = res.data.bill_amount
                this.un_bill_amount = res.data.un_bill_amount
                this.tableData = res.data.list;
                this.pagination.total = res.data.total;
            });
        },
        onClose() {
            this.drawer.show = false;
        },
        judge(arr) {
            this.stateColl = []
            for (let i of arr) {
                if (i.every(el => el == i[0]) && i[0] == 1) {
                    this.stateColl.push(1)
                    continue
                }
                if (i.every(el => el == i[0]) && i[0] == 2) {
                    this.stateColl.push(2)
                    continue
                }
                this.stateColl.push(3)
            }
        },
        check(row) {
            console.log(row);
            this.billNUmber = row.waybill_no;
            this.amount = row.amount;
            expenseCosts({
                waybillId: row.waybill_id,
            }).then((res) => {
                this.infoData = res.data || [];
                this.typeDate = []
                let costs = []
                for (let i of this.infoData) {
                    costs = []
                    for (let j of i.costs) {
                        costs.push(j.is_write_off)
                    }
                    this.typeDate.push(costs)
                }
                this.judge(this.typeDate)
                this.drawer.show = true;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.box {
    .title {
        display: flex;
        font-size: 10px;
        justify-content: space-between;
    }

    .accounts {
        display: inline-block;
        color: #d18f6c;
        height: 20px;
        width: 60px;
        background: #fff4f9;
        text-align: center;
        border-radius: 15px;
    }

    .unaccounts {
        display: inline-block;
        color: #7dd15c;
        height: 20px;
        width: 60px;
        background: #f0ffe4;
        text-align: center;
        border-radius: 15px;
    }

    .verification {
        display: inline-block;
        color: #c97482;
        height: 20px;
        width: 60px;
        background: #fee3e4;
        text-align: center;
        border-radius: 15px;
    }

    .part_verification {
        display: inline-block;
        color: #f9d27e;
        height: 20px;
        width: 80px;
        background: #fdeaca;
        text-align: center;
        border-radius: 15px;
    }

    .un_verification {
        display: inline-block;
        color: #3e95e6;
        height: 20px;
        width: 80px;
        background: #a9d7f7;
        text-align: center;
        border-radius: 15px;
    }

    .table {
        margin-bottom: 15px;

        .head {
            display: flex;
            justify-content: space-between;
            //   font-weight: bolder;
            margin-bottom: 15px;
            margin-top: 15px;
        }
    }
}
</style>